<template>
  <div id="ui">
    <md-content class="md-elevation-4 md-alignment-center-center card-altice-detail" style="padding-bottom: 30px;">
      <md-toolbar class="md-transparent" md-elevation="0">
        <h3 class="md-title md-toolbar-section-start" style="flex: 1">{{ sanity.email }}</h3>
      </md-toolbar>
      <md-button v-if="hideButton === false" class="md-raised md-secondary" @click="del()">Desativar utilizador</md-button>
      <md-button v-if="hideButton === false" class="md-raised md-secondary" @click="change()">Alterar Email</md-button>
      <h4 v-if="hideButton !== false" class="md-title md-toolbar-section-start" style="flex: 1">Tradato!</h4>
    </md-content>
  </div>
</template>

<script>
import Sanity from '@/api/services/Sanity.js'
import Swal from 'sweetalert2'

export default {
  name: 'Sanity',
  props: ['uuid'],
  components: {
  },
  data () {
    return {
      sanity: {},
      hideButton: false
    }
  },
  methods: {
    del () {
      Swal.fire({
        // title: 'Deseja que o nosso gestor de projeto contacte?',
        text: 'Deseja realmente desativar o utilizador?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0084D6',
        cancelButtonColor: '#333333',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          Sanity.delete(this.$route.params.uuid).then((response) => {
            Swal.fire(
              'Dasativado!',
              '',
              'success'
            )
          }).catch(() => {
            Swal.fire(
              'Atenção!',
              'Não foi possível dasativar no momento, tente novamente!',
              'error'
            )
            this.$loading(false)
          })
        }
      })
    },
    change () {
      Swal.mixin({
        confirmButtonText: 'Próximo',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        progressSteps: ['1', '2'],
        confirmButtonColor: '#0084D6',
        cancelButtonColor: '#333333',
        reverseButtons: true
      }).queue([
        {
          // title: 'Assunto',
          input: 'text',
          text: 'Novo email'
        },
        {
          input: 'text',
          text: 'Nome',
          confirmButtonText: 'Alterar'
        }
      ]).then((result) => {
        if (result.value) {
          if (result.value[0] && result.value[1]) {
            const data = {
              'token': this.$route.params.uuid,
              'email': result.value[0],
              'name': result.value[1]
            }
            Sanity.change(this.$route.params.uuid, data).then((response) => {
              Swal.fire(
                'Alterado!',
                'obrigado.',
                'success'
              )
            }).catch(() => {
              Swal.fire(
                'Atenção!',
                'Não foi possível alterar no momento, tente novamente!',
                'error'
              )
              this.$loading(false)
            })
          } else {
            Swal.fire(
              'Atenção!',
              'Todos os campos são obrigatórios.',
              'error'
            )
          }
        }
      })
    }
  },
  created () {
    Sanity.getSanity(this.$route.params.uuid).then(response => {
      this.sanity = response.data
      if (this.sanity.solved) {
        this.hideButton = true
      }
    })
  }
}
</script>

<style lang="scss">
.card-altice-detail {
  margin: auto auto;
  max-width: 1000px;
  padding-left: 15px;
}
.md-raised{
  border-radius:5px;
  box-shadow: none!important;
  border: 2px solid black;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color: transparent !important;
  .icon-menu{
    font-size:10px!important;
    color:black!important;
    width:auto!important;
    min-width:auto!important;
  }
}

.md-raised:hover{
  border-radius:5px;
  box-shadow: none!important;
  border-color: #0084D6!important;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color:#0084D6!important;

  .md-button-content{
    color:white!important;
    .icon-menu{
      color:white!important;
    }
  }
}
</style>

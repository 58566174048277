import * as Api from '@/api'

const SANITY = '/ad/sanity/:id'

export default {
  getSanity (id) {
    return Api.http(true).get(SANITY.replace(':id', id, {}))
  },
  delete (id) {
    return Api.http(true).delete(SANITY.replace(':id', id, {}))
  },
  change (id, data) {
    return Api.http(true).put(SANITY.replace(':id', id), data)
  }
}
